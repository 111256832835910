const converters = {
  namespaced: true,

  jobType(id) {
    switch (id.toString()) {
      case "1":
        return "PNS";
      case "2":
        return "TNI / POLRI";
      case "3":
        return "Karyawan BUMN";
      case "4":
        return "Karyawan Swasta";
      case "5":
        return "Wiraswasta";
      case "6":
        return "Pelajar / Mahasiswa";
      case "7":
        return "Lain-lain";
      case "8":
        return "Tidak bekerja / bukan pelajar";
      case "9":
        return "Badan Hukum";
      case "10":
      default:
        return "Tidak Ada Data";
    }
  }, 

  religion(id) {
    switch (id.toString()) {
      case "1":
        return "Islam";
      case "2":
        return "Katolik";
      case "3":
        return "Protestan";
      case "4":
        return "Hindu";
      case "5":
        return "Buddha";
      case "6":
        return "Konghucu";
      case "7":
        return "Lain-lain";
      case "8":
        return "Badan Hukum";
      case "9":
      default:
        return "Tidak Ada Data";
    }
  },

  gender(id) {
    switch (id.toString()) {
      case "1":
        return "Laki-laki";
      case "2":
        return "Perempuan";
      case "3":
        return "Badan Hukum";
      default:
        return "Tidak Ada Data";
    }
  },

  maritalStatus(id) {
    switch (id.toString()) {
      case "1":
        return "Sudah Kawin";
      case "2":
        return "Belum Kawin";
      case "3":
        return "Badan Hukum";
      case "4":
      default:
        return "Tidak Ada Data";
    }
  },

  collateral(id) {
    switch (id.toString()) {
      case "1":
        return "Properti";
      case "2":
        return "Kendaraan";
      case "3":
        return "Barang Inventaris";
      case "4":
        return "Surat Berharga";
      case "5":
        return "Hak Tagih";
      case "6":
        return "Letter of Credit";
      case "7":
        return "Lainnya";
      case "8":
        return "Tidak Memiliki Agunan";
      case "9":
      default:
        return "Tidak Ada Data";
    }
  },

  month(val) {
    switch (val.toString()) {
      case "01":
        return "Januari";
      case "02":
        return "Februari";
      case "03":
        return "Maret";
      case "04":
        return "April";
      case "05":
        return "Mei";
      case "06":
        return "Juni";
      case "07":
        return "Juli";
      case "08":
        return "Agustus";
      case "09":
        return "September";
      case "10":
        return "Oktober";
      case "11":
        return "November";
      case "12":
        return "Desember";
    }
  },


  jobMiddleware(id) {
    switch (id.toString()) {
      case "1":
        return "PNS";
      case "2":
        return "Karyawan BUMN";
      case "3":
        return "Karyawan Swasta";
      case "4":
        return "Pelajar / Mahasiswa";
      case "5":
        return "Lain-lain";
      case "6":
        return "Tidak bekerja / bukan pelajar";
      case "7":
        return "Wiraswasta";
      case "8":
        return "TNI / POLRI";
      case "9":
      default:
        return "Tidak Ada Data";
    }
  },

  jobTypeMiddleware(id) {
    switch (id.toString()) {
      case "1":
        return "Pertanian, Perburuan dan Kehutanan";
      case "2":
        return "Perikanan";
      case "3":
        return "Pertambangan dan Penggalian";
      case "4":
        return "Industri Pengolahan";
      case "5":
        return "Listrik, Gas, dan Air";
      case "6":
        return "Konstruksi";
      case "7":
        return "Perdagangan Besar dan Eceran";
      case "8":
        return "Penyediaan Akomodasi dan Penyediaan Makan Minum";
      case "9":
        return "Transportasi, Pergudangan, dan Komunikasi";
      case "10":
        return "Perantara Keuangan";
      case "11":
        return "Administrasi Pemerintahan, Pertahanan, dan Jaminan Sosial Wajib";
      case "12":
        return "Jasa Pendidikan";
      case "13":
        return "Jasa Kesehatan dan Kegiatan Sosial";
      case "14":
        return "Kegiatan Organisasi Yang Tidak Diklasifikasi Ditempat Lain";
      case "15":
        return "Jasa Perorangan yang Melayani Rumah Tangga";
      case "16":
        return "Badan Internasional dan Badan Ekstra Internasional Lainnya";
      case "17":
        return "Kegiatan yang Belum Jelas Batasannya";
      case "18":
        return "Tidak Bekerja / Bukan Pelajar";
      case "19":
        return "Pelajar / Mahasiswa";
      case "20":
        return "Real Estate, Usaha Persewaan, dan Jasa Perusahaan";   
      case "21":
      default:
        return "Tidak Ada Data";
    }
  },

  jobOnlineMiddleware(id) {
    switch (id.toString()) {
      case "1":
        return "Berbasis Internet / online";
      case "2":
        return "Tidak Berbasis Internet / Offline";
      default:
        return "Tidak Ada Data";
    }
  },

  incomeMiddleware(id) {
    switch (id.toString()) {
      case "1":
        return "Rp 0 - Rp 2.967.020";
      case "2":
        return "Rp 2.967.021 - Rp 5.934.040";
      case "3":
        return "Rp 5.934.041 - Rp 11.868.080";
      case "4":
        return "Rp 11.868.081 - Rp 25.000.000";
      case "5":
        return "Rp 25.000.001 - Rp 208.333.333";
      case "6":
        return "Rp 208.333.334 - Rp 4.166.666.667";
      case "7":
        return ">Rp 4.166.666.667";
      case "8":
      default:
        return "Tidak Ada Data";
    }
  },

  assetMiddleware(id) {
    switch (id.toString()) {
      case "1":
        return "<Rp 12.500.000";
      case "2":
        return "Rp 12.500.001 - Rp 50.000.000";
      case "3":
        return "Rp 50.000.001 - Rp 500.000.000";
      case "4":
        return "Rp 500.000.001 - 50.000.000.000";
      case "5":
        return "> Rp50.000.000.000";
      case "6":
      default:
        return "Tidak Ada Data";
    }
  },

  homeOwnershipMiddleware(id) {
    switch (id.toString()) {
      case "1":
        return "BUKAN MILIK SENDIRI";
      case "2":
        return "MILIK SENDIRI";
      case "3":
      default:
        return "Tidak Ada Data";
    }
  },

  businessOwnershipMiddleware(id) {
    switch (id.toString()) {
      case "1":
        return "BUKAN MILIK SENDIRI";
      case "2":
        return "MILIK SENDIRI";
      case "3":
      default:
        return "Tidak Ada Data";
    }
  },

  emergencyContactRelationshipMiddleware(id) {
    switch (id.toString()) {
      case "1":
        return "Saudara";
      case "2":
        return "Istri";
      case "3":
        return "Suami";
      case "4":
        return "Adik";
      case "5":
        return "Kakak";
      case "6":
        return "Orang Tua";
      case "7":
      default:
        return "Tidak Ada Data";
    }
  },

  religionMiddleware(id) {
    switch (id.toString()) {
      case "1":
        return "Islam";
      case "2":
        return "Katolik";
      case "3":
        return "Protestan";
      case "4":
        return "Hindu";
      case "5":
        return "Buddha";
      case "6":
        return "Konghucu";
      case "7":
        return "Lain-lain";
      case "8":
      default:
        return "Tidak Ada Data";
    }
  },

  maritalStatusMiddleware(id) {
    switch (id.toString()) {
      case "1":
        return "Sudah Kawin";
      case "2":
        return "Belum Kawin";
      case "3":
      default:
        return "Tidak Ada Data";
    }
  },

  educationMiddleware(id) {
    switch (id.toString()) {
      case "1":
        return "SD";
      case "2":
        return "SMP";
      case "3":
        return "SMA/SMK";
      case "4":
        return "DIPLOMA";
      case "5":
        return "SARJANA";
      case "6":
        return "MAGISTER";
      case "7":
        return "DOKTOR";
      case "8":
      default:
        return "Tidak Ada Data";
    }
  },

  workExperienceMiddleware(id) {
    switch (id.toString()) {
      case "1":
        return "Pelajar atau belum pernah bekerja";
      case "2":
        return "< 1 Tahun";
      case "3":
        return "1 s/d 2 Tahun";
      case "4":
        return "2 s/d 3 Tahun";
      case "5":
        return "> 3 Tahun";
      case "6":
      default:
        return "Tidak Ada Data";
    }
  },

  bankNameMiddleware(id) {
    switch (id.toString()) {
      case "1":
        return "BANK SINARMAS";
      case "2":
        return "Bank Central Asia";
      case "3":
        return "BNI";
      case "4":
      default:
        return "Tidak Ada Data";
    }
  },

  legalTypeMiddleware(id) {
    switch (id.toString()) {
      case "1":
        return "PT";
      case "2":
        return "Koperasi";
      case "3":
        return "Pemerintah Pusat";
      case "4":
        return "Pemerintah Daerah";
      case "5":
        return "Lain-lain";
      case "6":
        return "Perorangan";
      case "7":
        return "Badan Hukum";
      case "8":
      default:
        return "Tidak Ada Data";
    }
  },

  date(val) {
    if (val) return val.slice(0, -14);
    else return "Tidak ada Data";
  },

  mediumDate(val) {
    if (val) return val.slice(0, -10);
    else return "Tidak ada Data";
  },

  shortDate(val) {
    if (val) return val.slice(0, -9);
    else return "Tidak ada Data";
  },

  shortDateInput(val) {
    if (val && val !== '0000-00-00 00:00:00') return val.slice(0, -9);
    else return "";
  },

  days(val) {
    return val + " hari";
  },

  currency(val) {
    if (val) return "Rp " + val.toLocaleString("id-ID") + ",00";
    else return "Tidak ada Data";
  },
};

export default converters;
